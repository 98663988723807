import { MAX_WEBP_DIMENSION, uploadButtonLabel } from '@constants'
import {
  CloudArrowUpIcon,
  ExclamationCircleIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import {
  Box,
  Button,
  DialogContent,
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/joy'
import { colors, globalStyles } from '@styles/styles'
import { getImageAspectRatio, isValidAspectRatio, sizeInKB, sizeInMB, sizeToBytes } from '@utils'
import React, { useState } from 'react'

interface Props {
  onChange: (image: File[] | null) => void
  multiple?: boolean
  disabled?: boolean
  acceptedTypes?: string
  accept?: string
  maxFileSize?: number
  label?: React.ReactNode
  tooltip?: React.ReactNode
  acceptAspectRatio?: string
  aspectRatioTolerance?: number
  validateDimensions?: boolean
  iconButton?: boolean
}

const styles = {
  dropArea: {
    color: globalStyles.secondaryTextColor,
    border: globalStyles.dashedBorder,
    borderRadius: 8,
    p: 3,
    textAlign: 'center',
    my: '0.5rem',
    text: { color: colors.secondaryText },
  },
  button: { mt: 1 },
  sizeAlertModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    container: {
      maxWidth: 500,
      p: 3,
    },
    closeButton: { mt: 3, float: 'right' },
  },
}

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`

const FileUploadInput = ({
  onChange,
  acceptedTypes,
  multiple,
  disabled,
  maxFileSize = Infinity,
  label,
  tooltip,
  accept = '',
  acceptAspectRatio,
  aspectRatioTolerance = 0.2,
  validateDimensions,
  iconButton,
}: Props) => {
  const noSpacesAccept = accept.replace(/\s/g, '').replace(/\./g, '').toLowerCase()
  const acceptArray = noSpacesAccept.split(',').filter(Boolean)

  const isValidFile = ({
    file,
    aspectRatio,
    width,
    height,
  }: {
    file: File
    aspectRatio: string
    width: number
    height: number
  }) => {
    const validSize = file.size <= maxFileSize

    const fileExtension = file.name.split('.').pop() ?? ''
    const validType = accept ? acceptArray.includes(fileExtension.toLocaleLowerCase()) : true

    const validAspectRatio = acceptAspectRatio
      ? isValidAspectRatio({ aspectRatio, validAspectRatio: acceptAspectRatio, tolerance: aspectRatioTolerance })
      : true

    const validDimensions = validateDimensions
      ? width > 0 && height > 0 && width <= MAX_WEBP_DIMENSION && height <= MAX_WEBP_DIMENSION
      : true

    return validSize && validType && validAspectRatio && validDimensions
  }
  const [showSizeAlert, setShowSizeAlert] = useState<false | string[]>(false)

  const sizeToString = (size: number) => {
    return size < sizeToBytes(1, 'MB') ? `${sizeInKB(size)}KB` : `${sizeInMB(size)}MB`
  }

  const handleFileUpload = async (e: any) => {
    const files = Array.from(e?.target?.files ?? []) as File[]
    const filesWithAspectRatio =
      !acceptAspectRatio && !validateDimensions
        ? files.map((file) => ({ file, aspectRatio: '', width: 0, height: 0 }))
        : await Promise.all(files.map(async (file) => ({ file, ...(await getImageAspectRatio(file)) })))

    const invalidFileList = filesWithAspectRatio
      .filter((file) => !isValidFile(file))
      .map(
        ({ file: { name, size }, aspectRatio, width, height }) =>
          `${name} - (${sizeToString(size)}) ${acceptAspectRatio ? `- (${aspectRatio})` : ''} ${
            validateDimensions ? `- (${width}x${height})` : ''
          }`,
      )
    if (invalidFileList.length) {
      setShowSizeAlert(invalidFileList)
    }
    const validFiles = filesWithAspectRatio.filter(isValidFile)
    if (!validFiles.length) return
    onChange(validFiles.map(({ file }) => file))
  }

  return (
    <>
      {!iconButton ? (
        <>
          {label && (
            <Stack
              direction="row"
              gap={1}
            >
              <Typography
                level="body-md-light"
                color="neutral"
              >
                {label}
              </Typography>
              {tooltip && (
                <Tooltip title={tooltip}>
                  <QuestionMarkCircleIcon
                    style={{
                      color: 'rgba(var(--joy-palette-neutral-mainChannel) / 1)',
                    }}
                    width={'1em'}
                  />
                </Tooltip>
              )}
            </Stack>
          )}
          <Box
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault()
              handleFileUpload({ target: { files: Array.from(e.dataTransfer.items).map((item) => item.getAsFile()) } })
            }}
            sx={styles.dropArea}
          >
            <UploadButton
              handleFileUpload={handleFileUpload}
              acceptedTypes={accept ? accept : acceptedTypes ?? ''}
              multiple={multiple}
              disabled={disabled}
            />
          </Box>
        </>
      ) : (
        <IconButton
          disabled={disabled}
          component="label"
          variant="plain"
          role={undefined}
        >
          <PencilIcon width={'1em'} />
          <VisuallyHiddenInput
            type="file"
            onChange={handleFileUpload}
            accept={accept ? accept : acceptedTypes ?? ''}
            multiple={multiple}
          />
        </IconButton>
      )}
      <Modal
        open={Boolean(showSizeAlert)}
        onClose={() => setShowSizeAlert(false)}
        sx={styles.sizeAlertModal}
      >
        <ModalDialog sx={{ maxWidth: '360px', padding: '45px' }}>
          <Stack
            gap={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ExclamationCircleIcon
              strokeWidth="1"
              style={{
                width: '86px',
                color: 'var(--joy-palette-danger-plainColor)',
              }}
            />
            <Typography
              level="h2"
              sx={{ textAlign: 'center' }}
            >
              Error en la carga de archivos
            </Typography>
            <Stack gap={2}>
              <Stack sx={{ maxWidth: '100%' }}>
                <Typography level="title-lg-bold">Los siguientes archivos no pudieron ser cargados:</Typography>
                {showSizeAlert !== false &&
                  showSizeAlert.map((file) => (
                    <Typography
                      level="body-md-light"
                      color="neutral"
                      key={file}
                      sx={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {file}
                    </Typography>
                  ))}
              </Stack>
              <Stack>
                <Typography level="title-lg-bold">
                  Recuerde que los archivos deben cumplir con los requerimientos:
                </Typography>
                {maxFileSize !== Infinity && (
                  <Typography
                    level="body-md-light"
                    color="neutral"
                  >
                    Peso máximo por archivo: {sizeToString(maxFileSize)}
                  </Typography>
                )}
                {Boolean(accept) && (
                  <Typography
                    level="body-md-light"
                    color="neutral"
                  >
                    Formatos: {accept}
                  </Typography>
                )}
                {Boolean(acceptAspectRatio) && (
                  <Typography
                    level="body-md-light"
                    color="neutral"
                  >
                    Relación de aspecto: {`${acceptAspectRatio} (± ${aspectRatioTolerance * 100}%)`}
                  </Typography>
                )}
                {validateDimensions && (
                  <Typography
                    level="body-md-light"
                    color="neutral"
                  >
                    Dimensiones máximas: {MAX_WEBP_DIMENSION}x{MAX_WEBP_DIMENSION}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
          <DialogContent></DialogContent>

          <Button
            onClick={() => {
              setShowSizeAlert(false)
            }}
            sx={styles.sizeAlertModal.closeButton}
          >
            Cerrar
          </Button>
        </ModalDialog>
      </Modal>
    </>
  )
}

interface UploadButtonProps {
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
  acceptedTypes: string
  multiple?: boolean
  disabled?: boolean
}

const UploadButton = ({ handleFileUpload, acceptedTypes, multiple, disabled }: UploadButtonProps) => (
  <Button
    component="label"
    tabIndex={-1}
    disabled={disabled}
    variant="plain"
    color="neutral"
    startDecorator={<CloudArrowUpIcon />}
    sx={{
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }}
  >
    <Typography
      level="body-md-light"
      color="neutral"
      sx={{ mr: 0.5 }}
    >
      Arrastre los archivos aquí o
    </Typography>
    <Typography
      color="primary"
      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
    >
      {uploadButtonLabel}
    </Typography>
    <VisuallyHiddenInput
      type="file"
      onChange={handleFileUpload}
      accept={acceptedTypes}
      multiple={multiple}
    />
  </Button>
)
export default FileUploadInput
