import Stack from '@mui/joy/Stack'
import SpinnerCenter from '@components/SpinnerCenter'
import { useAppDispatch, useAppSelector } from '@redux/reduxHooks'
import { changeTableConfig } from '@slices/tableConfigsSlice'
import { Link, navigate } from 'gatsby'
import React, { ChangeEvent, Fragment, useState, useEffect, ReactNode } from 'react'
import Typography from '@mui/joy/Typography'
import { globalStyles } from '@styles/styles'
import Box from '@mui/joy/Box'
import Switch from '@mui/joy/Switch'
import Accordion from '@mui/joy/Accordion'
import AccordionSummary from '@mui/joy/AccordionSummary'
import AccordionDetails from '@mui/joy/AccordionDetails'
import Button from '@mui/joy/Button'
import { Circle, DeleteOutline, GridView, List, MoreVertOutlined, Search } from '@mui/icons-material'
import Checkbox from '@mui/joy/Checkbox'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab from '@mui/joy/Tab'
import Table from '@mui/joy/Table'
import Tooltip from '@mui/joy/Tooltip'
import Dropdown from '@mui/joy/Dropdown'
import MenuButton from '@mui/joy/MenuButton'
import AccordionGroup from '@mui/joy/AccordionGroup'
import IconButton from '@mui/joy/IconButton'
import Input from '@mui/joy/Input'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import { useDebouncedCallback } from 'use-debounce'
import Layout from '@components/Layout'
import { AspectRatio, Grid, ListItemDecorator, Divider, Skeleton } from '@mui/joy'
import {
  BuildingOffice2Icon,
  DocumentDuplicateIcon,
  EnvelopeOpenIcon,
  ExclamationCircleIcon,
  EyeIcon,
  HomeIcon,
  InboxArrowDownIcon,
  KeyIcon,
  PauseIcon,
  PencilIcon,
  StarIcon,
  TagIcon,
  TrashIcon,
  UserIcon,
  XMarkIcon,
  CheckCircleIcon,
  NoSymbolIcon,
  ArrowDownTrayIcon,
  DocumentArrowDownIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid'
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid'
import { TableSkeleton } from '@components/Skeletons'
import { TableResponse } from '@types'
import { DateInput } from '@components/Forms/Inputs'
import moment from 'moment'

interface TableConfig {
  [key: string]: {
    header: string
    sortType?: string
    width?: string
  }
}

interface Row {
  activeRow?: boolean
  content: {
    [key: keyof TableConfig]: {
      render: JSX.Element
      sortValue?: string | number | Date
    }
  }
}

interface RowCard {
  title: string
  date: string
  image: string
  button: React.ReactNode
  fallbackJSX?: JSX.Element
}

interface CustomFilters {
  [key: keyof TableConfig]: string[]
}

interface Props {
  meta?: TableResponse<unknown>['meta']
  useBackendFilter?: boolean
  cards?: RowCard[]
  rows: Row[]
  fetching?: boolean
  tableConfig: TableConfig
  extraElements?: JSX.Element
  extraLeftBody?: ReactNode
  options?: {
    activeTabName?: string
    inactiveTabName?: string
    hiddenTabs?: boolean
    hiddenSearchBar?: boolean
    hiddenFilters?: boolean
    customFilters?: CustomFilters
    customBodyHeight?: string
    tableSelection?: {
      title: string
      link: string
      hidden?: boolean
      disabled?: boolean
    }[]
    hiddenActiveFilters?: boolean
  }
  onTabChange?: (activeTab: string) => void
  isLoading?: boolean
  error?: boolean
  onErrorReload?: () => void
  forbidden?: boolean
  uniqueId?: string
}

const styles = {
  leftPanel: {
    resultsSummary: {
      height: globalStyles.topBarHeight,
    },
    activeFilters: {
      paddingX: 2,
      paddingY: 1,
      border: globalStyles.border,
      borderRadius: globalStyles.borderRadius,
    },
    activeFiltersSwitch: {
      marginRight: 1,
    },
    filters: {
      paddingX: 1,
      paddingY: 1,
      border: globalStyles.border,
      borderRadius: globalStyles.borderRadius,
    },
    appliedFilters: {
      title: {
        marginTop: 1,
        justifyContent: 'space-between',
      },
      bodyItem: {
        border: '1px solid var(--joy-palette-primary-outlinedColor)',
        borderRadius: globalStyles.borderRadius,
        paddingX: 1,
      },
      bodyItemText: {
        color: 'var(--joy-palette-primary-outlinedColor)',
      },
    },
    availableFilters: {
      box: {
        paddingX: 1,
        paddingY: 1,
        marginBottom: 2,
        marginTop: 2,
        border: globalStyles.border,
        borderRadius: globalStyles.borderRadius,
      },
      outsideAccordion: { paddingY: 2 },
      insideAccordion: { paddingY: 2 },
    },
  },
  rightPanel: {
    tabsBar: {
      global: {
        width: '100%',
      },
      tab: {
        height: globalStyles.topBarHeight,
        borderBottom: globalStyles.border,
        display: { xs: 'none', sm: 'block' },
      },
      extraElements: {
        height: globalStyles.topBarHeight,
      },
    },
  },
}

export const DynamicTable = ({
  rows,
  fetching,
  tableConfig,
  extraElements,
  options,
  onTabChange,
  useBackendFilter,
  meta,
  cards,
  isLoading: loading,
  extraLeftBody,
  error,
  forbidden,
  onErrorReload,
  uniqueId,
}: Props) => {
  const isLoading = loading || error || forbidden
  const isBrowser = typeof window !== 'undefined'
  const noTrailingSlashLocation = isBrowser ? location.pathname.replace(/\/$/, '') : undefined

  const tableState = useAppSelector(
    (state) => state.tableConfigs[uniqueId ?? (noTrailingSlashLocation as keyof typeof state.tableConfigs)],
  )
  const dispatch = useAppDispatch()
  const iterableTableConfig = Object.keys(tableConfig)

  const [sorted, setSorted] = useState<{ direction?: 'asc' | 'desc'; key?: keyof TableConfig }>(
    tableState?.sorted ?? {},
  )

  const [filter, setFilter] = useState<{ [key: keyof TableConfig]: ReactNode } | undefined>(tableState?.filter ?? {})
  const [search, setSearch] = useState(tableState?.search ?? '')
  const [undebouncedSearch, setUndebouncedSearch] = useState(tableState?.search ?? '')
  const [excludedKeys, setExcludedKeys] = useState<string[]>(tableState?.excludedKeys ?? [])
  const [activeTab, setActiveTab] = useState<string>(tableState?.activeTab ?? 'all')
  const [resultsView, setResultsView] = useState<'table' | 'grid'>(Boolean(cards) ? 'grid' : 'table')

  const handleSort = (tableKey: keyof TableConfig) => {
    setSorted((sort) => ({ direction: sort?.direction === 'asc' ? 'desc' : 'asc', key: tableKey }))
  }

  const debounceSearch = useDebouncedCallback((value) => {
    setSearch(value)
  }, 500)

  useEffect(() => {
    debounceSearch(undebouncedSearch)
  }, [undebouncedSearch])

  useEffect(() => {
    dispatch(
      changeTableConfig(uniqueId ?? noTrailingSlashLocation, { filter, search, sorted, excludedKeys, activeTab }),
    )
  }, [filter, search, sorted, excludedKeys, activeTab])

  const sortRows = (rows: Row[]) =>
    rows.sort((a, b) => {
      if (!sorted.key) return 1
      if (typeof a.content[sorted.key]?.sortValue === undefined) return 1
      let c = a,
        d = b
      if (sorted?.direction === 'desc') {
        c = b
        d = a
      }
      switch (tableConfig[sorted.key].sortType) {
        case 'number':
          return (
            ((c.content[sorted.key].sortValue as number) ?? 0) - //
            ((d.content[sorted.key].sortValue as number) ?? 0)
          )
        case 'string':
          return (c.content[sorted.key].sortValue as string)
            .toLocaleString()
            .toLowerCase()
            .localeCompare(
              (d.content[sorted.key].sortValue as string) //
                .toLocaleString()
                .toLowerCase(),
            )
        case 'date':
          return (
            new Date(c.content[sorted.key].sortValue as string).getTime() - //
            new Date(d.content[sorted.key].sortValue as string).getTime()
          )
        default:
          return 1
      }
    })

  const filterRows = (rows: Row[]) =>
    rows.filter(
      (row) =>
        !Object.keys(tableConfig)
          .map((tableKey) =>
            options?.customFilters?.[tableKey]
              ? !!filter?.[tableKey] &&
                !(row.content[tableKey]?.render?.props.children as string).includes(filter?.[tableKey] as string)
              : !!filter?.[tableKey] && filter?.[tableKey] !== row.content[tableKey]?.render?.props.children,
          )
          .some((noMatches) => noMatches),
    )

  const searchRows = (rows: Row[]) =>
    rows.filter((row) =>
      Object.keys(tableConfig)
        .map(
          (tableKey) =>
            !search ||
            row.content[tableKey]?.render?.props?.children?.toString().toLowerCase().includes(search.toLowerCase()),
        )
        .some((matches) => matches),
    )

  const rowsToShow = useBackendFilter
    ? rows
    : activeTab === 'active'
    ? sortRows(rows)?.filter((row) => row.activeRow)
    : activeTab === 'inactive'
    ? sortRows(rows)?.filter((row) => !row.activeRow)
    : sortRows(rows)
  const filteredRows = useBackendFilter ? rows : searchRows(filterRows(rowsToShow))

  const grid = (cards: RowCard[]) => (
    <Grid
      container
      spacing={4}
      sx={{ padding: 4 }}
    >
      {cards!.map((card, index) => (
        <Grid
          key={index}
          xs={12}
          sm={6}
          lg={4}
        >
          <AspectRatio sx={{ position: 'relative', borderRadius: globalStyles.card.borderRadius }}>
            {card.image ? (
              <img
                src={card.image}
                alt=""
              />
            ) : (
              card.fallbackJSX
            )}
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                padding: 2,
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: 'linear-gradient(to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.8))',
                alignItems: 'end',
              }}
            >
              <Stack sx={{ flex: 1, minWidth: 0 }}>
                <Typography
                  level="body-sm-light"
                  sx={{ color: 'white' }}
                >
                  {card.date}
                </Typography>
                <Typography
                  level="h3"
                  sx={{
                    color: 'white',
                  }}
                  noWrap
                  textOverflow="ellipsis"
                >
                  {card.title}
                </Typography>
              </Stack>
              <Stack sx={{ paddingY: 1, flexShrink: 0 }}>{card.button}</Stack>
            </Stack>
          </AspectRatio>
        </Grid>
      ))}
    </Grid>
  )

  const availableTableSelection = options?.tableSelection?.filter(({ hidden }) => !hidden)
  const linkIncludesActualLocation = (link: string) => noTrailingSlashLocation && link.includes(noTrailingSlashLocation)

  return (
    <Layout
      customBodyHeight={options?.customBodyHeight}
      hiddenLeftPanel={options?.hiddenFilters}
      hiddenTopBar={options?.hiddenSearchBar}
      loaders={{ right: fetching && !isLoading }}
      leftHeader={
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={styles.leftPanel.resultsSummary}
        >
          <Typography level="title-lg-bold">
            {isLoading ? (
              <Skeleton loading={isLoading}>Mostrando 00 resultado(s)</Skeleton>
            ) : (
              <>
                {'Mostrando '}
                <Typography
                  color="primary"
                  level="title-lg-bold"
                >
                  {`${filteredRows.length} resultado(s)`}
                </Typography>
              </>
            )}
          </Typography>
        </Stack>
      }
      leftBody={
        <Stack gap={2}>
          {!options?.hiddenTabs &&
            (isLoading ? (
              <Box>
                <Skeleton
                  loading
                  variant="rectangular"
                  height="88px" // TODO: improve height calculation
                  width="100%"
                />
              </Box>
            ) : (
              <Box sx={styles.leftPanel.activeFilters}>
                <Stack gap={1}>
                  <Typography
                    component={'span'}
                    sx={{
                      color: activeTab === 'all' ? undefined : globalStyles.secondaryTextColor,
                    }}
                    startDecorator={
                      <Switch
                        size="sm"
                        sx={styles.leftPanel.activeFiltersSwitch}
                        checked={activeTab === 'all'}
                        onChange={() => {
                          setActiveTab('all')
                          onTabChange?.('all')
                        }}
                      ></Switch>
                    }
                  >
                    {`Todos (${meta?.totals.total ?? ''})`}
                  </Typography>
                  <Typography
                    component={'span'}
                    sx={{
                      color: activeTab === 'active' ? undefined : globalStyles.secondaryTextColor,
                    }}
                    startDecorator={
                      <Switch
                        size="sm"
                        sx={styles.leftPanel.activeFiltersSwitch}
                        checked={activeTab === 'active'}
                        onChange={() => {
                          setActiveTab('active')
                          onTabChange?.('active')
                        }}
                      ></Switch>
                    }
                  >
                    {`${options?.activeTabName ?? 'Activas'} (${meta?.totals.actives ?? ''})`}
                  </Typography>
                  <Typography
                    component={'span'}
                    sx={{
                      color: activeTab === 'inactive' ? undefined : globalStyles.secondaryTextColor,
                    }}
                    startDecorator={
                      <Switch
                        size="sm"
                        sx={styles.leftPanel.activeFiltersSwitch}
                        checked={activeTab === 'inactive'}
                        onChange={() => {
                          setActiveTab('inactive')
                          onTabChange?.('inactive')
                        }}
                      ></Switch>
                    }
                  >
                    {`${options?.inactiveTabName ?? 'Desactivadas'} (${meta?.totals.inactives ?? ''})`}
                  </Typography>
                </Stack>
              </Box>
            ))}
          {isLoading ? (
            <Box>
              <Skeleton
                variant="rectangular"
                height="42px" // TODO: improve height calculation
                width="100%"
              />
            </Box>
          ) : (
            <Box sx={styles.leftPanel.filters}>
              <Accordion defaultExpanded={options?.hiddenTabs}>
                <AccordionSummary>
                  <Typography level="title-lg-bold">
                    {options?.hiddenTabs ? (
                      'Filtrando'
                    ) : (
                      <>
                        Filtrando en{' '}
                        <Typography
                          level="title-lg-bold"
                          color="primary"
                        >
                          {activeTab === 'active'
                            ? options?.activeTabName ?? 'Activas'
                            : activeTab === 'inactive'
                            ? options?.inactiveTabName ?? 'No activas'
                            : 'Todos'}
                        </Typography>
                      </>
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={styles.leftPanel.appliedFilters.title}
                  >
                    <Typography>Aplicados</Typography>
                    <Button
                      sx={{
                        paddingX: 0,
                      }}
                      onClick={() => setFilter({})}
                      variant="plain"
                    >
                      <Typography level="body-md-light">Borrar todos</Typography>
                    </Button>
                  </Stack>
                  <Stack gap={1}>
                    {Object.keys(filter ?? {})
                      .filter((filterKey) =>
                        Array.isArray(filter?.[filterKey])
                          ? (filter?.[filterKey] as Array<any>)?.length
                          : filter?.[filterKey],
                      )
                      .map((filterKey, index) =>
                        Array.isArray(filter?.[filterKey]) ? (
                          (filter?.[filterKey] as Array<any>).map((filterValue, valueIndex) => (
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={styles.leftPanel.appliedFilters.bodyItem}
                              key={index + filterKey + valueIndex}
                            >
                              <Typography
                                sx={styles.leftPanel.appliedFilters.bodyItemText}
                                level="body-md"
                              >
                                {filterValue}
                              </Typography>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  setFilter((prevFilter) => ({
                                    ...prevFilter,
                                    [filterKey]: (prevFilter?.[filterKey] as Array<any>).filter(
                                      (value) => value !== filterValue,
                                    ),
                                  }))
                                }
                              >
                                <DeleteOutline />
                              </IconButton>
                            </Stack>
                          ))
                        ) : (
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={styles.leftPanel.appliedFilters.bodyItem}
                            key={index + filterKey}
                          >
                            <Typography
                              sx={styles.leftPanel.appliedFilters.bodyItemText}
                              level="body-md"
                            >
                              {moment(filter?.[filterKey]?.toString()).isValid()
                                ? // ? moment(filter?.[filterKey]?.toString()).format('DD/MM/YYYY')
                                  new Date(filter?.[filterKey]?.toString()!).toLocaleDateString()
                                : filter?.[filterKey]}
                            </Typography>
                            <IconButton
                              color="primary"
                              onClick={() => setFilter((prevFilter) => ({ ...prevFilter, [filterKey]: undefined }))}
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Stack>
                        ),
                      )}
                  </Stack>
                  <Box sx={styles.leftPanel.availableFilters.box}>
                    <Accordion defaultExpanded={options?.hiddenTabs}>
                      <AccordionSummary>
                        <Typography level="title-lg-bold">Filtros disponibles</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={styles.leftPanel.availableFilters.outsideAccordion}>
                        <AccordionGroup>
                          {(!useBackendFilter ? iterableTableConfig : meta?.filters)?.map(
                            (tableKey: any, index: number) => {
                              const thisHeader = !useBackendFilter
                                ? tableConfig[tableKey].header
                                : tableKey.label ?? tableConfig[tableKey.key]?.header ?? tableKey.key
                              return (
                                (!useBackendFilter ? Boolean(tableConfig[tableKey].sortType) : true) && (
                                  <Accordion
                                    sx={{
                                      px: 0,
                                    }}
                                    key={index}
                                  >
                                    <AccordionSummary>
                                      <Typography level="title-lg-light">{thisHeader}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={styles.leftPanel.availableFilters.insideAccordion}>
                                      <Stack gap={1}>
                                        {!options?.customFilters?.[tableKey] || useBackendFilter
                                          ? (!useBackendFilter
                                              ? rows.filter(
                                                  (uniqueRow, rowIndex) =>
                                                    rows.findIndex(
                                                      (row) =>
                                                        row.content[tableKey]?.sortValue ===
                                                        uniqueRow.content[tableKey]?.sortValue,
                                                    ) === rowIndex,
                                                )
                                              : tableKey.values
                                            ).map((row: any, optionIndex: number) => {
                                              const checkboxValue = !useBackendFilter
                                                ? row.content[tableKey]?.render?.props.children
                                                : row.name
                                              const checkValue = !tableKey?.multiple
                                                ? checkboxValue
                                                : [...((filter?.[tableKey.key] as Array<any>) ?? []), row.name]
                                              const unckeckValue =
                                                !useBackendFilter || !tableKey?.multiple
                                                  ? undefined
                                                  : (filter?.[tableKey.key] as Array<any>)?.filter(
                                                      (value) => value !== row.name,
                                                    )
                                              const isFilterApplied = !tableKey?.multiple
                                                ? filter?.[!useBackendFilter ? tableKey : tableKey.key] ===
                                                  checkboxValue
                                                : (
                                                    filter?.[!useBackendFilter ? tableKey : tableKey.key] as Array<any>
                                                  )?.includes(checkboxValue)
                                              return (
                                                <Checkbox
                                                  checked={Boolean(isFilterApplied)}
                                                  label={`${ellipt(checkboxValue)} (${row.total})`}
                                                  sx={{
                                                    color: isFilterApplied
                                                      ? undefined
                                                      : globalStyles.secondaryTextColor,
                                                  }}
                                                  onChange={({ target: { checked } }) =>
                                                    setFilter((prevFilter) => ({
                                                      ...prevFilter,
                                                      [!useBackendFilter ? tableKey : tableKey.key]: checked
                                                        ? checkValue
                                                        : unckeckValue,
                                                    }))
                                                  }
                                                  key={`option-${optionIndex}`}
                                                ></Checkbox>
                                              )
                                            })
                                          : options?.customFilters?.[tableKey].map((option, optionIndex) => (
                                              <Checkbox
                                                label={option}
                                                onClick={() =>
                                                  setFilter((prevFilter) => ({ ...prevFilter, [tableKey]: option }))
                                                }
                                                key={`option-${optionIndex}`}
                                              ></Checkbox>
                                            ))}
                                      </Stack>
                                    </AccordionDetails>
                                  </Accordion>
                                )
                              )
                            },
                          )}
                          {useBackendFilter &&
                            meta?.custom_filters?.map((tableKey, index) => (
                              <Accordion
                                sx={{
                                  px: 0,
                                }}
                                key={index}
                              >
                                <AccordionSummary>
                                  <Typography level="title-lg-light">{tableKey.label}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={styles.leftPanel.availableFilters.insideAccordion}>
                                  <Stack gap={1}>
                                    {tableKey.type === 'date' && (
                                      <DateInput<any>
                                        type="date"
                                        name={tableKey.key}
                                        value={(filter?.[tableKey.key] as string) ?? ''}
                                        onChange={(_, value) =>
                                          setFilter((prevFilter) => ({ ...prevFilter, [tableKey.key]: value }))
                                        }
                                      />
                                    )}
                                  </Stack>
                                </AccordionDetails>
                              </Accordion>
                            ))}
                        </AccordionGroup>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          {Boolean(extraLeftBody) && isLoading ? (
            <Box>
              <Skeleton
                loading
                variant="rectangular"
                height="88px" // TODO: improve height calculation
                width="100%"
              />
            </Box>
          ) : (
            extraLeftBody
          )}
        </Stack>
      }
      rightHeader={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="end"
          sx={styles.rightPanel.tabsBar.global}
        >
          <ModuleTabs
            tabs={options?.tableSelection}
            isLoading={isLoading}
          />
          <Stack
            sx={styles.rightPanel.tabsBar.extraElements}
            alignItems="center"
            alignContent={'center'}
            direction="row"
            gap={2}
          >
            <div className="d-flex justify-content-end me-3">{extraElements ?? false}</div>
            {!options?.hiddenSearchBar &&
              (isLoading ? (
                <Box>
                  <Skeleton
                    variant="rectangular"
                    height="1.5em"
                    width="233px" // TODO: improve width calculation
                  />
                </Box>
              ) : (
                <Input
                  aria-label="Buscar"
                  value={undebouncedSearch}
                  placeholder={`Buscar en ${
                    availableTableSelection
                      ?.find((table) => linkIncludesActualLocation(table.link))
                      ?.title.toLowerCase() ?? 'resultados'
                  }`}
                  endDecorator={<Search />}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setUndebouncedSearch(e.target.value)}
                ></Input>
              ))}
            {!options?.hiddenFilters && false && (
              <Dropdown>
                <MenuButton startDecorator={<List />} />
                <Menu>
                  <MenuItem
                    onClick={() => setExcludedKeys([])} //
                  >
                    <Checkbox
                      label={'Todos'}
                      checked={!excludedKeys.length}
                      onChange={() => null}
                    />
                  </MenuItem>
                  {iterableTableConfig.map((tableKey, index: number) => {
                    const thisHeader = tableConfig[tableKey].header
                    return (
                      <MenuItem
                        key={`option-${index}`}
                        onClick={() => {
                          setExcludedKeys((prevKeys) =>
                            prevKeys.includes(thisHeader)
                              ? prevKeys.filter((key) => key !== thisHeader)
                              : [...prevKeys, thisHeader],
                          )
                        }}
                      >
                        <Checkbox
                          label={thisHeader}
                          checked={!excludedKeys.includes(thisHeader)}
                          onChange={() => null}
                        />
                      </MenuItem>
                    )
                  })}
                </Menu>
              </Dropdown>
            )}
            {Boolean(cards) && (
              <Stack direction="row">
                <Button
                  variant="plain"
                  color="neutral"
                  size="sm"
                  onClick={() => setResultsView('table')}
                  startDecorator={<List />}
                ></Button>
                <Divider orientation="vertical" />
                <Button
                  size="sm"
                  variant="plain"
                  color="neutral"
                  onClick={() => setResultsView('grid')}
                  startDecorator={<GridView />}
                ></Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      }
      // rightBody={resultsView === 'table' ? table(rowsToShow) : grid(cards!)}
      rightBody={
        resultsView === 'table' ? (
          <ResultsTable
            rows={useBackendFilter ? rows : searchRows(filterRows(rows))}
            fetching={fetching}
            tableConfig={tableConfig}
            useBackendFilter={useBackendFilter}
            iterableTableConfig={iterableTableConfig}
            excludedKeys={excludedKeys}
            meta={meta}
            handleSort={handleSort}
            sorted={sorted}
            isLoading={isLoading}
          />
        ) : (
          grid(cards!)
        )
      }
      error={error}
      onErrorReload={onErrorReload}
      forbidden={forbidden}
    />
  )
}

interface ResultsTableProps {
  rows: Row[]
  fetching?: boolean
  tableConfig: TableConfig
  iterableTableConfig: string[]
  excludedKeys: string[]
  useBackendFilter?: boolean
  meta?: {
    sorted: string[]
  }
  handleSort: (tableKey: keyof TableConfig) => void
  sorted: { direction?: 'asc' | 'desc'; key?: keyof TableConfig }
  isLoading?: boolean
}

const ResultsTable = ({
  rows: rowsToShow,
  fetching,
  tableConfig,
  iterableTableConfig,
  excludedKeys,
  useBackendFilter,
  meta,
  handleSort,
  sorted,
  isLoading,
}: ResultsTableProps) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  useEffect(() => {
    setSelectedRows([])
  }, [rowsToShow])

  const customColumnWidth: { [key: string]: string } = {
    '': '5%',
    Id: '50px',
    Estado: '10%',
    Portada: '10%',
    Acciones: '10%',
    '# Ticket': '50px',
  }

  const customHeaderJustify: { [key: string]: string } = {
    Acciones: 'center',
  }

  return isLoading ? (
    <TableSkeleton />
  ) : (
    <>
      <Table sx={{ cursor: fetching ? 'wait' : 'default' }}>
        <thead>
          <tr
            style={{
              height: globalStyles.navBarHeight,
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          >
            <th style={{ width: '2%', textAlign: 'center' }}>
              {false && (
                <Checkbox
                  checked={selectedRows.length === rowsToShow.length && selectedRows.length > 0}
                  onChange={() =>
                    setSelectedRows((prevSelectedRows) =>
                      prevSelectedRows.length === rowsToShow.length ? [] : rowsToShow.map((_, index) => index),
                    )
                  }
                ></Checkbox>
              )}
            </th>
            {iterableTableConfig
              .filter((tableKey) => !excludedKeys.includes(tableConfig[tableKey].header))
              .map((tableKey, index: number) => (
                <th
                  style={{
                    cursor: !useBackendFilter
                      ? tableConfig[tableKey].sortType
                        ? 'pointer'
                        : undefined
                      : meta?.sorted.includes(tableKey)
                      ? 'pointer'
                      : undefined,
                    width: tableConfig[tableKey].width ?? customColumnWidth[tableConfig[tableKey].header],
                  }}
                  key={`header-${index}`}
                  onClick={() =>
                    (!useBackendFilter ? tableConfig[tableKey].sortType : meta?.sorted.includes(tableKey))
                      ? handleSort(tableKey)
                      : {}
                  }
                >
                  <Stack
                    gap={1}
                    direction="row"
                    alignItems="center"
                    height="100%"
                    justifyContent={customHeaderJustify[tableConfig[tableKey].header] ?? 'start'}
                  >
                    <Typography
                      sx={{ color: globalStyles.secondaryTextColor }}
                      level="body-md-light"
                    >
                      {tableConfig[tableKey].header}
                    </Typography>
                    <TableOrderIcon
                      show={Boolean(
                        !useBackendFilter ? tableConfig[tableKey].sortType : meta?.sorted.includes(tableKey),
                      )}
                      order={sorted?.key === tableKey ? sorted.direction : undefined}
                    />
                  </Stack>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {rowsToShow.map((row, rowIndex) => (
            <tr
              className={row.activeRow ? '' : 'disabled-cells'}
              key={`row-${rowIndex}`}
            >
              <td style={{ textAlign: 'center' }}>
                {false && (
                  <Checkbox
                    checked={selectedRows.includes(rowIndex)}
                    onChange={() =>
                      setSelectedRows((prevSelectedRows) =>
                        prevSelectedRows.includes(rowIndex)
                          ? prevSelectedRows.filter((selectedRow) => selectedRow !== rowIndex)
                          : [...prevSelectedRows, rowIndex],
                      )
                    }
                  ></Checkbox>
                )}
              </td>
              {iterableTableConfig
                .filter((tableKey) => !excludedKeys.includes(tableConfig[tableKey].header))
                .map((tableKey) => {
                  const render = row.content[tableKey]?.render
                  return <Fragment key={tableKey}>{render ?? <td></td>}</Fragment>
                })}
            </tr>
          ))}
        </tbody>
      </Table>
      {!rowsToShow.length && (
        <p
          style={{
            marginTop: '1rem',
            marginBottom: '1rem',
            textAlign: 'center',
          }}
        >
          <i>No hay elementos para mostrar</i>
        </p>
      )}
    </>
  )
}

interface SwitchCellProps {
  disabled?: boolean
  checked: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  label?: string
}

export const SwitchCell = ({ disabled, checked, onChange, label }: SwitchCellProps) => (
  <td>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        level="body-md"
        component={'span'}
        startDecorator={
          <Switch
            sx={{ marginRight: 1 }}
            size="sm"
            {...{ disabled, checked, onChange, label }}
          />
        }
      >
        {/* {label} */}
      </Typography>
    </Box>
  </td>
)

interface TextCellProps {
  children?: ReactNode
  ellipsis?: number
  cellStyles?: React.CSSProperties
}

const ellipt = (children: any, ellipsis: number = 50) =>
  typeof children === 'string' && children.length > ellipsis ? children.slice(0, ellipsis - 1) + '\u2026' : children

export const TextCell = ({ children, ellipsis = 50, cellStyles }: TextCellProps) => {
  const isEllipted = !(typeof children !== 'string' || children.length < ellipsis)

  const td = (
    <td style={cellStyles}>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
        }}
      >
        {!isEllipted ? children : children?.slice(0, ellipsis - 1) + '\u2026'}
      </Box>
    </td>
  )

  return isEllipted ? (
    <Tooltip
      placement="top"
      title={children}
    >
      {td}
    </Tooltip>
  ) : (
    td
  )
}

export const sortablePlainTextCell = (text?: string, ellipsis?: number, loading?: boolean) => ({
  render: loading ? <SpinnerCell /> : <TextCell {...{ ellipsis }}>{text ?? ''}</TextCell>, //
  sortValue: text ?? '',
})

export const sortableNumberCell = (value?: number) => ({
  render: <TextCell>{value?.toString() ?? ''}</TextCell>, //
  sortValue: value?.toString() ?? '',
})

export const sortableDateCell = (date?: string, justDate?: boolean) => ({
  render: (
    <TextCell>
      {date ? (justDate ? new Date(date).toLocaleDateString() : new Date(date).toLocaleString()) : '-'}
    </TextCell>
  ), //
  sortValue: new Date(date ?? 0),
})
export const isActiveCell = (is_active: boolean, activeText?: string, inactiveText?: string) => ({
  render: (
    <td>
      <Typography
        color={is_active ? 'success' : 'neutral'}
        startDecorator={<Circle sx={{ fontSize: '1rem' }} />}
      >
        {is_active ? activeText ?? 'Activa' : inactiveText ?? 'Desactivada'}
      </Typography>
    </td>
  ),
})

export const featuredCell = ({
  featured,
  onClick,
  disabled,
}: {
  featured: boolean
  onClick?: () => void
  disabled?: boolean
}) => ({
  render: (
    <td>
      <IconButton
        color={!featured ? 'neutral' : 'warning'}
        disabled={disabled}
        onClick={onClick}
      >
        {featured ? <StarIconSolid /> : <StarIcon />}
      </IconButton>
    </td>
  ),
})

interface EditIconProps {
  path: string
  disabled?: boolean
}

export const EditIcon = ({
  path,
  disabled,
  ...rest
}: EditIconProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <Tooltip
    placement="top"
    title={'Editar'}
  >
    <i
      {...rest}
      onClick={() => !disabled && navigate(path, { state: { prevPath: location.pathname } })}
      className={`material-icons mx-1 ${disabled ? 'text-muted' : ''}`}
      style={{ cursor: disabled ? 'inherit' : 'pointer', fontSize: '1.5rem' }}
    >
      drive_file_rename_outline
    </i>
  </Tooltip>
)

interface DeleteIconProps {
  disabled?: boolean
  onClick?: () => void
}

export const DeleteIcon = ({
  disabled,
  onClick,
  ...rest
}: DeleteIconProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <Tooltip
    placement="top"
    title={'Eliminar'}
  >
    <i
      {...rest}
      onClick={() => !disabled && onClick?.()}
      className={`material-icons mx-1 ${disabled ? 'text-muted' : ''}`}
      style={{ cursor: disabled ? 'inherit' : 'pointer', fontSize: '1.5rem' }}
    >
      delete_outline
    </i>
  </Tooltip>
)

interface CustomIconProps {
  icon: string
  tooltip: string
  path: string
  disabled?: boolean
  iconCategory?: 'outlined'
}

export const CustomIcon = ({
  tooltip,
  path,
  disabled,
  icon,
  iconCategory,
  ...rest
}: CustomIconProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <Tooltip
    placement="top"
    title={tooltip}
  >
    <i
      {...rest}
      onClick={() => !disabled && navigate(path, { state: { prevPath: location.pathname } })}
      className={`material-icons${iconCategory ? `-${iconCategory}` : ''} mx-1 ${disabled ? 'text-muted' : ''}`}
      style={{ cursor: disabled ? 'inherit' : 'pointer', fontSize: '1.5rem' }}
    >
      {icon}
    </i>
  </Tooltip>
)

export const SpinnerCell = () => (
  <td className="align-middle text-center">
    <SpinnerCenter size="sm" />
  </td>
)

interface ThumbnailCellProps {
  src?: string
  fallbackJSX?: JSX.Element
}

export const ThumbnailCell = ({ src, fallbackJSX }: ThumbnailCellProps) => (
  <td>
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {src ? (
        <img
          style={{
            objectFit: 'contain',
          }}
          className="thumbnail"
          src={src}
        ></img>
      ) : (
        fallbackJSX
      )}
    </Box>
  </td>
)

const iconWidth = '20px'

const icons = {
  Editar: <PencilIcon style={{ width: iconWidth }} />,
  Eliminar: <TrashIcon style={{ width: iconWidth }} />,
  Previsualizar: <EyeIcon style={{ width: iconWidth }} />,
  Activar: <CheckCircleIcon style={{ width: iconWidth }} />,
  Desactivar: <NoSymbolIcon style={{ width: iconWidth }} />,
  'Descargar PDF': <DocumentArrowDownIcon style={{ width: iconWidth }} />,

  pencil: <PencilIcon style={{ width: iconWidth }} />,
  trash: <TrashIcon style={{ width: iconWidth }} />,
  documentDuplicate: <DocumentDuplicateIcon style={{ width: iconWidth }} />,
  eye: <EyeIcon style={{ width: iconWidth }} />,
  home: <HomeIcon style={{ width: iconWidth }} />,
  buildingOffice2: <BuildingOffice2Icon style={{ width: iconWidth }} />,
  star: <StarIcon style={{ width: iconWidth }} />,
  pause: <PauseIcon style={{ width: iconWidth }} />,
  xMark: <XMarkIcon style={{ width: iconWidth }} />,
  user: <UserIcon style={{ width: iconWidth }} />,
  envelopeOpen: <EnvelopeOpenIcon style={{ width: iconWidth }} />,
  inboxArrowDown: <InboxArrowDownIcon style={{ width: iconWidth }} />,
  exclamationCircle: <ExclamationCircleIcon style={{ width: iconWidth }} />,
  tag: <TagIcon style={{ width: iconWidth }} />,
  key: <KeyIcon style={{ width: iconWidth }} />,
  activeCircle: (
    <Circle
      color="success"
      style={{ width: iconWidth, fontSize: '1rem' }}
    />
  ),
  disabledCircle: (
    <Circle
      color="disabled"
      style={{ width: iconWidth, fontSize: '1rem' }}
    />
  ),
  Ver: <EyeIcon style={{ width: iconWidth }} />,
  Descargar: <ArrowDownTrayIcon style={{ width: iconWidth }} />,
}

interface ActionsMenuButtonProps {
  startDecorator?: ReactNode
  icon?: keyof typeof icons
  disabled?: boolean
  onClick?: () => void
  label: string
  hidden?: boolean
}

interface ActionsMenuProps {
  items: ActionsMenuButtonProps[]
}

export const ActionsMenu = ({ items }: ActionsMenuProps) => {
  return (
    <Dropdown>
      <MenuButton
        variant="plain"
        sx={{ p: 0.5 }}
      >
        <MoreVertOutlined />
      </MenuButton>
      <Menu sx={{ zIndex: '9999' }}>
        {items
          .filter(({ hidden }) => !hidden)
          .map((item, index) => (
            <MenuItem
              disabled={item.disabled}
              key={index}
              onClick={() => !item.disabled && item.onClick?.()}
            >
              <ListItemDecorator>
                {(item.icon ? icons[item.icon] : item.startDecorator) ?? icons[item.label as keyof typeof icons]}
              </ListItemDecorator>
              <Typography level="body-md-light">{item.label}</Typography>
            </MenuItem>
          ))}
      </Menu>
    </Dropdown>
  )
}
export const ActionsMenuCell = ({ items }: ActionsMenuProps) => (
  <td style={{ opacity: 1 }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ActionsMenu items={items} />
    </Box>
  </td>
)

export const CenteredCell = ({ children }: { children: ReactNode }) => (
  <td>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  </td>
)

export const TableOrderIcon = ({ order, show }: { order?: 'asc' | 'desc'; show?: boolean }) => {
  const width = '10px'
  const disabledOpacity = 0.5
  const translate = 25
  return (
    <Stack
      sx={{
        display: show ? undefined : 'none',
        justifyItems: 'center',
      }}
    >
      <ChevronUpIcon
        style={{
          transform: `translateY(${translate}%)`,
          opacity: order === 'asc' ? 1 : disabledOpacity,
          width,
        }}
      />
      <ChevronDownIcon
        style={{
          transform: `translateY(-${translate}%)`,
          opacity: order === 'desc' ? 1 : disabledOpacity,
          width,
        }}
      />
    </Stack>
  )
}

interface ModuleTabsProps {
  tabs?: { title: string; link: string; disabled?: boolean; hidden?: boolean }[]
  isLoading?: boolean
}

export const ModuleTabs = ({ tabs, isLoading }: ModuleTabsProps) => {
  const isBrowser = typeof window !== 'undefined'
  const noTrailingSlashLocation = isBrowser ? location.pathname.replace(/\/$/, '') : undefined
  const availableTableSelection = tabs?.filter(({ hidden }) => !hidden)
  const linkIncludesActualLocation = (link: string) =>
    (noTrailingSlashLocation && link.includes(noTrailingSlashLocation)) || link === location.pathname

  return (
    <Tabs value={availableTableSelection?.findIndex(({ link }) => linkIncludesActualLocation(link))}>
      <TabList disableUnderline>
        {availableTableSelection?.map(({ title, link, disabled }, index) => (
          <Link
            key={`tab-${index}-${title}`}
            to={disabled ? '' : link}
            style={{ textDecoration: 'none', backgroundColor: 'inherit' }}
          >
            <Tab
              color="primary"
              sx={{ height: globalStyles.topBarHeight, borderBottom: globalStyles.border }}
              disabled={disabled}
            >
              <Typography
                level={linkIncludesActualLocation(link) ? 'title-lg-bold' : 'title-lg-light'}
                sx={linkIncludesActualLocation(link) ? {} : { color: globalStyles.secondaryTextColor }}
              >
                <Skeleton loading={Boolean(isLoading)}>{title}</Skeleton>
              </Typography>
            </Tab>
          </Link>
        ))}
      </TabList>
    </Tabs>
  )
}
